<template>
  <v-app id="app">
    <v-app-bar app color="red darken-4" dark>
      <app-navigation />
    </v-app-bar>
    <v-main transition="slide-x-transition">
      <router-view />
    </v-main>
  </v-app>
</template>

<script>
import AppNavigation from '@/components/AppNavigation'

export default {
  name: 'PublicLayout',
  components: {
    AppNavigation,
  }
}
</script>

<style>
</style>
